/* eslint-disable react/jsx-props-no-spreading */
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import classnames from 'classnames';
import * as React from 'react';

import styles from './RadioGroup.module.scss';

const RadioGroupRoot = (props: React.ComponentProps<typeof RadixRadioGroup.Root>) => {
    return <RadixRadioGroup.Root {...props}>{props.children}</RadixRadioGroup.Root>;
};

type RadioGroupItemProps = React.ComponentProps<typeof RadixRadioGroup.Item> & {
    id: string;
    label: React.ReactNode;
    classname?: string;
};

const RadioGroupItem = ({ className, ...props }: RadioGroupItemProps) => {
    const classes = classnames(styles.Item, className);

    return (
        <div className={classes}>
            <RadixRadioGroup.Item className={styles.Input} {...props}>
                <RadixRadioGroup.Indicator className={styles.Indicator} />
            </RadixRadioGroup.Item>
            <label className={styles.Label} htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    );
};

const RadioGroup = {
    Root: RadioGroupRoot,
    Item: RadioGroupItem,
};

export default RadioGroup;
