import { ChevronLeftIcon, ChevronRightIcon } from '@coop/icons';
import classNames from 'classnames';
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';

import { AccessibleIcon } from '../Icon';
import PageNumber from './PageNumber';
import styles from './Pagination.module.scss';
import type { Page } from './Pagination.types';
import { calculatePaging } from './Pagination.utils';

interface PaginationProps {
    currentPage: number;
    totalResults: number;
    resultsPerPage: number;
    paginationRange: number;
    currentPath: string;
    currentSearchParams: URLSearchParams;
    onPageChange: (e: React.MouseEvent<HTMLAnchorElement>, page: Page) => void;
    hideIfOnlyOnePage?: boolean;
}

const Pagination = (props: React.PropsWithChildren<PaginationProps>) => {
    const { currentPage } = props;
    const pagingResult = calculatePaging(
        currentPage,
        props.totalResults,
        props.resultsPerPage,
        props.paginationRange,
    );

    const buildUrlWithPage = (page: Page) => {
        const queryParams = new URLSearchParams(props.currentSearchParams);

        if (page?.pageNumber > 1) {
            queryParams.set('page', page.pageNumber.toString());
        } else {
            queryParams.delete('page');
        }
        const newUrl = `${props.currentPath}${
            queryParams.toString() && `?${queryParams.toString()}`
        }`;
        return newUrl;
    };

    const getPage = (pageNumber: number) => {
        return pagingResult.pages.find((page) => page.pageNumber === pageNumber);
    };

    const prevPage = getPage(currentPage - 1);
    const nextPage = getPage(currentPage + 1);

    if (pagingResult.totalPages === 0) {
        return null;
    }

    if (pagingResult.totalPages === 1 && props.hideIfOnlyOnePage) {
        return null;
    }

    return (
        <div className={classNames(styles.Pagination)}>
            {currentPage > 1 && prevPage && (
                <a
                    href={buildUrlWithPage(prevPage)}
                    className={styles['Pagination-arrow']}
                    onClick={(e) => props.onPageChange(e, prevPage)}
                    rel="nofollow"
                >
                    <AccessibleIcon icon={ChevronLeftIcon} label="Föregående" />
                </a>
            )}
            {pagingResult.pages.map((page) => (
                <a
                    href={buildUrlWithPage(page)}
                    key={page.pageNumber}
                    className={classNames(
                        styles['Pagination-page'],
                        page.isCurrentPage && styles['is-active'],
                        page.complementaryPageType === 'left' && styles.LeftComplementaryPage,
                        page.complementaryPageType === 'right' && styles.RightComplementaryPage,
                    )}
                    onClick={(e) => props.onPageChange(e, page)}
                    rel={page?.pageNumber > 3 ? 'nofollow' : undefined}
                    aria-label={`Sida ${page.pageNumber}`}
                >
                    <PageNumber page={page} pagingResult={pagingResult} />
                </a>
            ))}
            {currentPage !== pagingResult.totalPages && nextPage && (
                <a
                    href={buildUrlWithPage(nextPage)}
                    className={styles['Pagination-arrow']}
                    onClick={(e) => props.onPageChange(e, nextPage)}
                    rel="nofollow"
                >
                    <AccessibleIcon icon={ChevronRightIcon} label="Nästa" />
                </a>
            )}
        </div>
    );
};

export default Pagination;
