import './index.css';

import React from 'react';
import { type Root, createRoot } from 'react-dom/client';

import App from './pages/App';

if (import.meta.hot) {
    import.meta.hot.accept((newModule) => {
        if (newModule) {
            // newModule is undefined when SyntaxError happened
            // eslint-disable-next-line no-console
            console.log('updated: count is now ', newModule.count);
        }
    });
}

// Run app if not IE

let root: Root | undefined;
const domNode = document.getElementById('root');

// eslint-disable-next-line func-names
document.addEventListener('DOMContentLoaded', function () {
    if (!root) {
        root = createRoot(domNode!);
        root.render(<App />);
    }
});
